@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;700&family=Heebo:wght@400;800");

.barlowregular {
  font-weight: 400;
  font-family: "Barlow", sans-serif;
}
.barlowmedium {
  font-weight: 500;
  font-family: "Barlow", sans-serif;
}
.barlowbold {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
}
.heeboregular {
  font-family: "Heebo", sans-serif;
  font-weight: 400;
}
.heeboextrabold {
  font-family: "Heebo", sans-serif;
  font-weight: 800;
}
body {
  font-family: "barlowregular";
}
a {
  text-decoration: none !important;
  color: black !important;
}
.progress-bar {
  background-color: rgba(6, 214, 161) !important;
}
.progress {
  font-size: 1rem !important;
  height: 40px !important;
  font-weight: bold !important;
}
.logo {
  width: 280px;
}
.heightfull {
  height: 70vh;
}
.titlesup {
  font-size: 20px;
  font-weight: bold;
  margin-left: 250px;
  text-align: right;
}
.title1 {
  margin-top: 10px;
  font-weight: bold;
  font-size: 20px;
}
.title2 {
  font-weight: bold;
  font-size: 20px;
}
.title3 {
  font-weight: bold;
  font-size: 28px;
}
.desc1 {
  max-width: 70%;
}
.image-right {
  max-height: 160px;
}
.icon-right {
  max-height: 120px;
}
.recuadro {
  background: linear-gradient(
    135deg,
    rgba(6, 214, 161, 0.2861519607843137) 0%,
    rgba(6, 214, 161, 1) 95%
  );
  border-radius: 10px;
  padding-left: 30px !important;
}
.checkbox {
  height: 20px;
  width: 20px;
  vertical-align: text-bottom;
  margin-right: 10px;
  margin-top: 5px;
}
.btnnext {
  padding: 8px 30px 8px 30px;
  background: linear-gradient(
    135deg,
    rgba(6, 214, 161, 0.2861519607843137) 0%,
    rgba(6, 214, 161, 1) 95%
  );
  color: black;
  font-weight: bold;
  font-size: 20px !important;
  border-radius: 5px !important;
}
.btnarrow {
  height: 26px;
  margin-top: 5px;
}
button {
  border: 0;
  border-radius: 5px !important;
}
button:hover {
  background: linear-gradient(
    135deg,
    rgba(6, 214, 161, 1) 0%,
    rgba(6, 214, 161, 0.2861519607843137) 95%
  );
}
.backbutton {
  padding-bottom: 4px;
  padding-right: 8px;
}
.textbox,
select {
  width: 100%;
  border: 0;
  margin-top: 5px !important;
  border-radius: 5px;
  padding-left: 10px;
}
.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  font-size: 50px;
  cursor: pointer;
  color: rgb(6, 214, 161);
}
.icon-position img {
  margin-left: 20px;
}
.icon-position:hover {
  background-color: rgb(6, 214, 161);
  color: white;
}
.css-1r475wt-Slider {
  margin-top: 8px;
  width: 100% !important;
  height: 14px !important;
}
.legendSlider {
  font-size: 12px;
  font-weight: bold;
}
.h90 {
  max-height: 80px !important;
}
@media (max-width: 992px) {
  .titlesup {
    margin-left: inherit;
    text-align: left;
  }
}
footer a {
  font-size: 12px;
}
.top-margin {
  margin-top: 40px;
}
